import React from 'react'
import Img from 'gatsby-image'
import Lightbox from 'react-images';

export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareOpen: false,
      anchorEl: null,
      lightbox: false,
      photos: props.photos.map(photo => Object.assign({
        srcSet: photo.image.localFile.childImageSharp.fluid.srcSet,
        src: photo.image.localFile.childImageSharp.fluid.srcSet
       })),
    };
  }

  gotoPrevLightboxImage() {
    const { photo } = this.state;
    this.setState({ photo: photo - 1 });
  }

  gotoNextLightboxImage() {
    const { photo } = this.state;
    this.setState({ photo: photo + 1 });
  }

  openLightbox(photo, event) {
    event.preventDefault();
    this.setState({ lightbox: true, photo });
  }

  closeLightbox() {
    this.setState({ lightbox: false });
  }

  render() {
    const { photos } = this.props;
    return (
      <div>
        <div className="columns">
          {photos.map((photo, i) => (
            <div className="column" key={i}>
              <a href={photo.image.localFile.childImageSharp.fluid.src} onClick={e => this.openLightbox(i, e)}>
                <Img fluid={photo.image.localFile.childImageSharp.fluid} />
              </a>
            </div>
          ))}
        </div>
        <Lightbox
          backdropClosesModal
          images={this.state.photos}
          currentImage={this.state.photo}
          isOpen={this.state.lightbox}
          onClickPrev={() => this.gotoPrevLightboxImage()}
          onClickNext={() => this.gotoNextLightboxImage()}
          onClose={() => this.closeLightbox()}
        />
      </div>
    );
  }
}
