import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery'
import TemplatePDF from '../files/project-briefing-form.pdf'

const drawPageImages = (slug, pageImages) => {
  let columnSize;
  if (slug === 'worldwide-exhibition-stand-logistics') {
    columnSize = 'is-8 is-offset-2';
  } else if (slug === 'exhibition-stand-installation-and-dismantling') {
    columnSize = 'is-5 is-offset-1';
  } else {
    columnSize = 'is-10 is-offset-1';
  }
  return (
    <div className="columns">
      <div className={`column ${columnSize}`}>
        <Gallery photos={pageImages} />
      </div>
    </div>
  )
}

const drawContactButton = (slug) => {
  const displayContactButtonOn = [
    'stand-design',
    'stand-build',
    'project-management-service',
    'exhibition-stand-installation-and-dismantling',
    'worldwide-exhibition-stand-logistics'
  ];
  const displayProjectBriefingButtonOn = [
    'project-management-service'
  ];

  if (displayContactButtonOn.includes(slug)) {
    return (
      <Link
        className="button is-success"
        to="/contact">
        Get In Touch
      </Link>
    );
  }
}

const drawProjectBriefingButton = (slug) => {
  const displayProjectBriefingButtonOn = [
    '/project-management-service/'
  ];

  if (displayProjectBriefingButtonOn.includes(slug)) {
    return (
      <a
        href={TemplatePDF}
        target="_blank"
        className="button is-success">
        Download Stand Design Brief Template
      </a>
    );
  }
}

export const PageTemplate = ({ title, slug, content, pageImages, featuredImage }) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h2
              className="title is-size-3 has-text-weight-bold is-bold-light has-underline"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </div>

        {featuredImage &&
          <div className="columns">
            <div className="column is-6 is-offset-1">
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
            <div className="column is-4">
              <Img fixed={featuredImage.localFile.childImageSharp.fixed} />
            </div>
          </div>
        }
        {!featuredImage &&
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        }

        <div className="columns">
          <div className="column is-10 is-offset-1">
            {drawContactButton(slug)} {drawProjectBriefingButton(slug)}
          </div>
        </div>
        {pageImages &&
          drawPageImages(slug, pageImages)
        }
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data, pageContext }) => {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO
        title={page.title}
        description={(page.acf.meta_description ? page.acf.meta_description : null)}
      />
    <PageTemplate title={page.title} slug={pageContext.slug.replace(/\//g, '')} content={page.content} pageImages={page.acf.page_images} featuredImage={page.featured_media} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      acf {
        meta_description
        page_images {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
